.avatar-description {
  text-align: center;
}

.social {
  text-align: center;
}

.resume-download {
  margin-top: 20px;
  text-align: center;
}
