.collapse-header-component {
  display: inline-block;
  width: calc(100% - 32px);
  vertical-align: top;
}

.collapse-header-text {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
}

.right-label {
  float: right;
}

@media all and (max-width: 576px) {
  .right-label {
    float: left;
  }
}
