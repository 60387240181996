@import '~antd/dist/antd.css';

.main {
  min-height: calc(100vh - 60px);
}

.header {
  padding: 0;
  position: fixed;
  z-index: 1;
  width: 100%;
}

.content {
  padding-top: 64px;
}

.divider {
  margin-top: 64px;
}

.footer {
  text-align: center;
  height: 60px;
}

.collapse-panel-links-button-collection {
  padding-bottom: 24px;
}
