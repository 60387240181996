.panel-description ul li {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: justify;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
